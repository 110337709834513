<template>
  <div class="hamburger-menu">
    <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
      <span class="material-symbols-rounded header-content__icon">menu</span>
    </button>
    <ul class="hamburger-menu__list" :class="{ 'is-active': isActive }">
      <button @click="isActive = !isActive" class="hamburger-menu__close-button" aria-label="Cerrar menú"><span class="material-symbols-rounded">cancel</span></button>
      <li class="hamburger-menu__item" @click="isActive = !isActive" v-for="(opcion, index) in routes" :key="index">
        <router-link :to="opcion.link">{{ opcion.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: "MenuComponent",
  setup() {
      const isActive = ref(false);
      const { t, locale }  = useI18n();
      const routes = reactive([
          {
            title      :  t("OpcionesMenu.0.title"),
            link      :  t("OpcionesMenu.0.link")
          },
          {
            title       :  t("OpcionesMenu.1.title"),
            link      :  t("OpcionesMenu.1.link")
          },
          {
            title       :  t("OpcionesMenu.2.title"),
            link      :  t("OpcionesMenu.2.link")
          },
          // {
          //   title       :  t("OpcionesMenu.3.title"),
          //   link      :  t("OpcionesMenu.3.link")
          // },
          /*{
            title       :  t("OpcionesMenu.4.title"),
            link      :  t("OpcionesMenu.4.link")
          },*/
      ])

      // Observar los cambios de idioma y actualiza los slides
      const updateSlides = () => {
        routes.forEach((opcion, index) => {
          opcion.title = t(`OpcionesMenu.${index}.title`);
          opcion.link = t(`OpcionesMenu.${index}.link`);
        });
      };

      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });
    return {
      isActive,
      routes
    };
  },
};
</script>
