import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/home.vue'),
  },
  {
    path: '/sportsbook',
    name: 'sportsbook-langen',
    component: () => import('../pages/sportsbook.vue'),  
  },
  {
    path: '/deportes',
    name: 'sportsbook-langes',
    component: () => import('../pages/deportes.vue'),  
  },
  {
    path: '/casino',
    name: 'casino-langen',
    component: () => import('../pages/casino.vue'),  
  },
  {
    path: '/casino-es',
    name: 'casino-langes',
    component: () => import('../pages/casino.vue'),  
  },
  {
    path: '/caballos',
    name: 'racebook-langes',
    component: () => import('../pages/caballos.vue'),  
  },
  {
    path: '/racebook',
    name: 'racebook-langen',
    component: () => import('../pages/caballos.vue'),  
  },

  {
    path: '/general-sportsbook-rules',
    name: 'general-sportsbook-rules-langen',
    component: () => import('../pages/reglas-generales-sportsBook.vue'),  
  },
  {
    path: '/reglas-generales-sportsBook',
    name: 'reglas-generales-sportsBook-langes',
    component: () => import('../pages/reglas-generales-sportsBook.vue'),  
  },

  {
    path: '/sports-rules',
    name: 'sports-rules-langen',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/reglas-deportivas',
    name: 'sports-rules-langes',
    component: () => import('../pages/sports-rules.vue'),  
  },

  {
    path: '/responsible-gaming',
    name: 'responsible-gaming-langen',
    component: () => import('../pages/juego-responsable.vue'),  
  },
  {
    path: '/juego-responsable',
    name: 'responsible-gaming-langes',
    component: () => import('../pages/juego-responsable.vue'),  
  },

  {
    path: '/reset-pass',
    name: 'reset-pass-langen',
    component: () => import('../pages/forgot-password.vue'),  
  },
  {
    path: '/restablecer-contrasena',
    name: 'reset-pass-langes',
    component: () => import('../pages/forgot-password.vue'),  
  },


  {
    path: '/props-builder',
    name: 'props-builder-langen',
    component: () => import('../pages/props-builder.vue'),  
  },
  {
    path: '/crea-tus-props',
    name: 'props-builder-langes',
    component: () => import('../pages/props-builder.vue'),  
  },


  {
    path: '/signup',
    name: 'signup-langen',
    component: () => import('../pages/registrarme.vue'),  
  },
  {
    path: '/registrarme',
    name: 'signup-langes',
    component: () => import('../pages/registrarme.vue'),  
  },


  {
    path: '/live-betting',
    name: 'live-betting-langen',
    component: () => import('../pages/apuestas-vivo.vue'),  
  },
  {
    path: '/apuestas-en-vivo',
    name: 'live-betting-langes',
    component: () => import('../pages/apuestas-vivo.vue'),  
  },


  {
    path: '/cockpit',
    name: 'cock-langen',
    component: () => import('../pages/gallos.vue'),  
  },
  {
    path: '/gallos',
    name: 'cock-langes',
    component: () => import('../pages/gallos.vue'),  
  },


  {
    path: '/casino-rules',
    name: 'casino-rules-langen',
    component: () => import('../pages/reglas-casino.vue'),  
  },
  {
    path: '/reglas-casino',
    name: 'casino-rules-langes',
    component: () => import('../pages/reglas-casino.vue'),  
  },

  {
    path: '/privacy-politics',
    name: 'privacy-politics-langen',
    component: () => import('../pages/politica-privacidad.vue'),  
  },
  {
    path: '/politicas-de-privacidad',
    name: 'privacy-politics-langes',
    component: () => import('../pages/politica-privacidad.vue'),  
  },


  {
    path: '/terms-conditions',
    name: 'terms-conditions-langen',
    component: () => import('../pages/terminos-condiciones.vue'),  
  },
  {
    path: '/terminos-y-condiciones',
    name: 'terms-conditions-langes',
    component: () => import('../pages/terminos-condiciones.vue'),  
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
})


export default router


